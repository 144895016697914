<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'BookItem',
  props: {
    bookName: {
      type: String,
      default: "Tên sách"
    },
    cover: {
      type: String,
      default: "https://via.placeholder.com/150"
    },
    price: {
      type: Number,
      default: 0
    },
    priceMarketing: {
      type: Number,
      default: 0
    },
    code: {
      type: String,
      default: "XXXXX"
    },
    editable: {
      type: Boolean,
      default: true
    },
    link: {
      type: String,
      default: "https://vntalking.com"
    }
  },
  data: () => ({
    chose: false
  }),
  computed: {
    ...mapGetters(['orderSummary'])
  },
  methods: {
    ...mapActions(['updateBooksOrder']),
    checkboxUpdated(newValue){
      // dispatch update state books in store.
      const book = {
        bookName: this.bookName,
        code: this.code,
        cover: this.cover,
        price: this.price,
        chose: newValue
      }
      this.updateBooksOrder(book);
    }
  }
}
</script>

<template>
  <div class="book-item-containner">
    <div class="d-flex flex-row align-center">
      <div class="align-center">
        <v-checkbox v-if="editable" 
          v-model="chose" 
          color="primary" 
          @change="checkboxUpdated"/>
      </div>
      <div class="cover align-center mr-4 ml-2">
        <v-img
          :src="cover"
          contain
          height="60"
          width="60"
        ></v-img>
      </div>
      <div class="d-flex flex-column">
        <div class="title-book">{{bookName || "Tên sản phẩm"}}</div>
        <div v-if="editable" class="price-book">
          <div>Giá lẻ: <span class="price">{{this.$helper.formatCurrency(price)}}</span><span class="price-marketing ml-2">{{this.$helper.formatCurrency(priceMarketing || price)}}</span></div>
          <div class=""><a class="read-more" :href="link" target="_blank">Đọc sách</a></div>
        </div>
        <div v-else class="price-book">
          <div v-if="orderSummary?.books?.length == 1">
            Giá: {{this.$helper.formatCurrency(price)}}
          </div>
          <div v-else>
            Giá đã giảm theo chính sách combo
          </div>
        </div>
      </div>
    </div>
    <div class="book-item-divider"/>
  </div>
  
</template>

<style scoped>
  .book-item-containner {
    margin: 5px 0 5px 0;
  }

  .title-book {
    color: black;
    font-weight: bold;
    font-size: 15px;
  }
  .price-book {
    color: grey;
    font-size: 14px;
  }
  .price-book span.price {
    color: #1976D2;
    font-weight: bold;
  }
  a.read-more {
    color: brown;
    text-decoration: none;
    font-weight: bold;
  }
  .book-item-divider {
    margin: 6px 0 6px 0;
    border: 1px dotted #a1a1a1;
  }
  .price-marketing {
    color: grey;
    font-weight: bold;
    text-decoration-line: line-through;
  }
</style>