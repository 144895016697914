import Vue from 'vue'
import Vuex from 'vuex'
import { getInitData } from '../httpClient/modules/data.api';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageDataBuilder:{
      header:{ background: "https://vntalking.com/wp-content/uploads/2023/01/order-page-banner.png", logo: "", title: "Đăng ký đặt sách", subTitle: "Vui lòng chọn sách phù hợp và điền thông tin để nhận sách"},
      books: [
        {
          "code": "JAVASCRIPT",
          "bookName": "Javascript từ cơ bản tới nâng cao",
          "price": 89000,
          "cover": "https://vntalking.com/wp-content/uploads/2023/01/book-Javascript.png"
        },
        {
          "code": "REACTJS",
          "bookName": "Lập trình React thật đơn giản",
          "price": 89000,
          "cover": "https://vntalking.com/wp-content/uploads/2023/01/Book-ReactJS.png"
        },
        {
          "code": "NODEJS",
          "bookName": "Lập trình NodeJS thật đơn giản",
          "price": 89000,
          "cover": "https://vntalking.com/wp-content/uploads/2023/01/Book-NodeJS.png"
        },
        {
          "code": "RNATIVE",
          "bookName": "Lập trình React Native thật đơn giản",
          "price": 119000,
          "cover": "https://vntalking.com/wp-content/uploads/2023/01/Book-ReactNative.png"
        }
      ],
      paymentInfo: {note: ""},
      coupons:[{ code: "VNTRN2023", expireAt: 1675144920000, discount: 10}],
      surveyinfo: {
        question: "Bạn biết tới sách này qua đâu?",
        options: [{id: 1, answer: "Google Search", value: 1}]
      },
      formula:[],
      note: {},
      services: {
        email: {
          apiKey: "xxxx",
          emailTemplate: "xxxx",
          recipient: "xxx",
          endpoint: "xxx"
        }
      }
    },
    orderInfo:{
      orderId: -1,
      buyer:{
        name: "",
        email: ""
      },
      orderSummary:{
        amount: 0,
        books: []
      },
      couponApplied: "",
      transferContent: "",
      surveyResult:[],
      paid: false
    },
    ui:{
      loading: false,
      showDialog: false,
      dialogMessage: "Không có nội dung nào cả",
      step: 1
    }
  },
  actions: {
    async initPageData({commit}) {
      const {docs: [config]} = await getInitData();
      // Giải mã dữ liệu response
      const {pageDataBuilder} = config?.data();
      // Lưu vào store
      commit("UPDATE_PAGE_DATA", pageDataBuilder);
      // Tạo mã đơn hàng
      const ORDER_ID = Math.floor(Math.random() * (999999 - 100000 + 1) + 10000);
      commit("UPDATE_ORDER_ID", ORDER_ID);
    },
    updatePayStatus({commit}, payload) {
      commit("UPDATE_PAY_STATUS", payload);
    },
    updateBooksOrder({commit}, payload) {
      const {chose = false} = payload;
      delete payload["chose"];
      commit("UPDATE_BOOK_ORDER", {book: payload, chose});
    },
    applyCoupon({commit}, payload) {
      // update coupon và tính toán lại tổng tiền
      commit("UPDATE_COUPON", payload);
      commit("UPDATE_TRANSFER_CONTENT");
    },
    displayLoading({commit}, isShow = false) {
      commit("UPDATE_LOADING", isShow);
    },
    showDialog({commit}, payload) {
      commit("UPDATE_DIALOG_STATE", {isShowDialog: true, message: payload});
    },
    hideDialog({commit}) {
      commit("UPDATE_DIALOG_STATE", false);
    },
    updateSurveyResult({commit}, payload) {
      commit("UPDATE_SURVEY_ANSWER", payload);
    },
    changeStep({commit}, step) {
      commit("UPDATE_STEP", step);
    }
  },
  mutations: {
    UPDATE_ORDER_ID(state, orderId = -1) {
      state.orderInfo.orderId = orderId;
    },
    UPDATE_PAGE_DATA(state, data) {
      state.pageDataBuilder = data;
    },
    UPDATE_BOOK_ORDER(state, {book, chose}) {
      const books = state.orderInfo?.orderSummary?.books || [];
      if(chose) {
        // thêm book khỏi danh sách các sách chọn mua
        const found = books.some(b => b.code == book.code);
        if(!found) state.orderInfo.orderSummary.books.push(book);
      } else {
        // Xóa book khỏi danh sách các sách chọn mua
        state.orderInfo.orderSummary.books = books.filter(b => b.code != book.code);
      }

      // Tính toán giá tiền và cập nhập vào amount
      const bookChoseCodes = state.orderInfo.orderSummary.books.map(b => b.code) || [];
      const formulaMatching = state.pageDataBuilder.formula.find(f => JSON.stringify(bookChoseCodes.sort()) === JSON.stringify(f.combo?.sort()));
      const totalPrice = formulaMatching?.price || 0;

      // Kiểm tra xem đã apply mã giảm giá chưa.
      if(state.orderInfo.couponApplied) {
        const foundCoupon = state.pageDataBuilder.coupons.find(c => c.code?.toUpperCase()?.trim() == state.orderInfo.couponApplied?.toUpperCase()?.trim());
        const {discount = 0} = foundCoupon;
        state.orderInfo.orderSummary.amount = (totalPrice - totalPrice * ((Number(discount) || 0) / 100)).toFixed(0);
      } else {
        state.orderInfo.orderSummary.amount = totalPrice;
      }

      // Tự động generate nội dung chuyển tiền
      // Quy tắc: Tên - MaDH <mã đơn hàng>...
      state.orderInfo.transferContent = 
       `${state.orderInfo.buyer?.name?.split(' ')?.pop() || "Tên bạn" } - MaDH ${state.orderInfo.orderId}` ;
    },
    UPDATE_NAME(state, name) {
      state.orderInfo.buyer.name = name;
      // Cập nhật lại nội dung chuyển khoản:
      state.orderInfo.transferContent = 
       `${state.orderInfo.buyer?.name?.split(' ')?.pop() || "Tên bạn" } - MaDH ${state.orderInfo.orderId}` ;
    },
    UPDATE_EMAIL(state, email) {
      state.orderInfo.buyer.email = email;
    },
    UPDATE_COUPON(state, payload) {
      const {code, discount} = payload;
      const amount = Number(state.orderInfo.orderSummary.amount) || 0;
      state.orderInfo.orderSummary.amount = (amount - amount * ((Number(discount) || 0) / 100)).toFixed(0);
      // Chỉ cho phép apply duy nhất một coupon.
      state.orderInfo.couponApplied = code;
    },
    UPDATE_LOADING(state, isShow = false) {
      state.ui.loading = isShow;
    },
    UPDATE_DIALOG_STATE(state, {isShowDialog, message}) {
      state.ui.showDialog = isShowDialog;
      state.ui.dialogMessage = message;
    },
    UPDATE_SURVEY_ANSWER(state, payload) {
      // Khảo sát chỉ chấp nhận một đáp án.
      state.orderInfo.surveyResult = [];
      state.orderInfo.surveyResult.push(payload);
    },
    UPDATE_STEP(state, step) {
      state.ui.step = step;
    },
    UPDATE_TRANSFER_CONTENT(state) {
      state.orderInfo.transferContent = 
       `${state.orderInfo.buyer?.name?.split(' ')?.pop() || "Tên bạn" } - MaDH ${state.orderInfo.orderId}` ;
    },
    UPDATE_PAY_STATUS(state, payload) {
      state.orderInfo.paid = payload;
    }
  },
  getters: {
    pageDataBuilder: state =>  state.pageDataBuilder,
    header: state => state.pageDataBuilder.header,
    books: state => state.pageDataBuilder.books,
    surveyData: state => state.pageDataBuilder.surveyinfo,
    couponsData: state => state.pageDataBuilder.coupons,
    paymentInfo: state => state.pageDataBuilder.paymentInfo,
    orderInfo: state => state.orderInfo,
    orderSummary: state => state.orderInfo?.orderSummary,
    transferContent: state => state.orderInfo.transferContent,
    isLoading: state => state.ui.loading,
    isShowDialog: state => state.ui.showDialog,
    dialogMessage: state => state.ui.dialogMessage,
    step: state => state.ui.step,
    emailService: state => state.pageDataBuilder.services.email
  },
  modules: {}
})
