<script>
import BlockTitle from './BlockTitle.vue';
export default {
  name: 'PaymentInfo',
  components:{BlockTitle},
}
</script>

<template>
 <div class="contact-containner component">
    <block-title text="Thông tin liên hệ"/>
    Bạn có bất kỳ thắc mắc nào, đừng ngần ngại liên hệ để mình hỗ trợ và giải đáp nhé.
    <ul>
      <li>Fanpage: <a href="https://facebook.com/vntalking" target="_blank" rel="noopener noreferrer">https://facebook.com/vntalking</a></li>
      <li>Email: <a href="mailto:support@vntalking.com">support@vntalking.com</a></li>
    </ul>
  </div>
</template>