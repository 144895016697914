<script>
import BlockTitle from './BlockTitle.vue';
import { mapGetters, mapActions} from 'vuex'

export default {
  name: 'Survey',
  components:{BlockTitle},
  data: () => ({
    surveyAnswer: undefined
  }),
  computed: {
    ...mapGetters(['surveyData'])
  },
  methods: {
    ...mapActions(["updateSurveyResult"]),
    changed(){
      const answerObject = this.surveyData.options.find(s => s.id == this.surveyAnswer) || {};
      this.updateSurveyResult(answerObject);
    },
  }
}
</script>

<template>
  <div class="survey-containner component">
    <block-title text="Khảo sát (không bắt buộc)"/>
    <div class="vnt-text">{{surveyData.question}}</div>
    <v-radio-group v-model="surveyAnswer" @change="changed">
      <v-radio
        class="black--text" 
        color="green"
        v-for="q in surveyData.options"
        :key="q.id"
        :label="`${q.answer}`"
        :value="q.value"
      >
        <span slot="label" class="black--text">{{q.answer}}</span>
      </v-radio>
    </v-radio-group>
  </div>
</template>