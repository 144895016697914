<script>
import { save} from '../httpClient/modules/data.api';
import { sendEmailToAdmin } from '../httpClient/modules/email.api';
import OrderSummary from '../components/OrderSummary.vue';
import PaymentInfoBlock from '../components/PaymentInfo.vue';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PaymentInfo',
  components: {OrderSummary, PaymentInfoBlock},
  computed: {
    ...mapGetters(['orderInfo', 'emailService', 'couponsData'])
  },
  methods: {
    ...mapActions(["displayLoading", "showDialog", "changeStep", "updatePayStatus"]),
    async submit() {
      this.displayLoading(true);
      try {
        await save(this.orderInfo);
        await sendEmailToAdmin(this.buildEmailContent());
        this.gotoDonePage();
      } catch (error) {
        console.log(error);
        this.showDialog("Đã có lỗi khi gửi thông tin đăng ký tới máy chủ. Vui lòng chat trực tiếp với admin qua fanpage.")
      } finally {
        this.displayLoading(false);
      }
    },
    checkboxUpdated(newValue){
      // dispatch update pay status
      this.updatePayStatus(newValue);
      
    },
    buildEmailContent() {
      let emailTemplate = this.emailService.emailTemplate || "";
      const today = new Date();
      const createAt = ('0' + today.getDate()).slice(-2) + '/'
                + ('0' + (today.getMonth()+1)).slice(-2) + '/'
                + today.getFullYear() + ", "
                + ('0' + today.getHours()).slice(-2) + ':'
                + ('0' + today.getMinutes()).slice(-2)
      emailTemplate = emailTemplate
        .replace("[CUSTOMER]", this.orderInfo.buyer.name)
        .replace("[EMAIL]", this.orderInfo.buyer.email)
        .replace("[TRANFER_CONTENT]", this.orderInfo.transferContent)
        .replace("[AMOUNT]", new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.orderInfo.orderSummary.amount || 0))
        .replace("[CREATED_AT]", `${createAt}`)
        .replace("[PAID_STATUS]", `${this.orderInfo.paid ? 'Đã thanh toán' : 'Chưa thanh toán'}`)
        .replace("[BOOKS]", this.orderInfo.orderSummary.books?.map(b=>b.code).join("+"));
        
      if(this.orderInfo.couponApplied && this.orderInfo.couponApplied?.trim() != "") {
         emailTemplate = emailTemplate.replace("[COUPON]", `${this.orderInfo.couponApplied} (${this.couponsData?.find(c=>c.code == this.orderInfo.couponApplied)?.discount}%)` )
      } else {
         emailTemplate = emailTemplate.replace("[COUPON]","Không có")
      }
      return emailTemplate;
    },
    gotoDonePage() {
      this.changeStep(3)
    }
  }
}
</script>

<template>
  <v-row class="payment-info-page">
    <v-col cols="12" md="7">
      <div class="block main-content">
        <h1 class="d-flex align-center">Gần xong rồi 🥳</h1>
        <div>Cám ơn bạn đã đặt sách. Chỉ còn bước cuối cùng là bạn thanh toán số tiền ở mục tổng tiền theo thông tin ngân hàng như bên dưới đây.<br><br>
          <b>Lưu ý nhỏ:</b><br>
          - Nội dung chuyển khoản nên để giống như chương trình đã tạo tự động ở bên dưới để mình xác nhận nhanh hơn.<br>
          - Sau khi chuyển khoản, thông thường sau tối đa 5 phút là bạn sẽ nhận được sách.<br>
          - Tuy nhiên đôi lúc bị chậm trễ, xin đừng ngần ngại liên hệ trực tiếp với mình qua các kênh sau:
          <ul>
            <li>Fanpage: <a href="https://facebook.com/vntalking" target="_blank" rel="noopener noreferrer">https://facebook.com/vntalking</a></li>
            <li>Email: <a href="mailto:support@vntalking.com">support@vntalking.com</a></li>
          </ul>
        </div>
        <payment-info-block/>
      </div>
    </v-col>
    <v-col cols="12" md="5">
      <div class="block sidebar">
        <order-summary/>
        <span class="payment-page-note">Sau khi thực hiện chuyển khoản xong, bạn nhấn nút <b class="color-primary">Gửi đăng ký</b> để bên mình chuyển sách luôn nhé.</span>
        <div class="align-center">
          <v-checkbox
            color="primary" 
            label="Xác nhận đã chuyển khoản thanh toán"
            @change="checkboxUpdated"
          />
        </div>
        <v-btn block class="mt-2" :color="orderInfo.paid ? 'green' : 'red'" dark elevation="2" @click="submit">Gửi đăng ký</v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped>
.payment-page-note {
  color: brown;
}
.color-primary {
  color: #4caf50;
}
</style>