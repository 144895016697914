import httpClient from '../httpClient';
import store from '../../store'

/**
 * Gửi email thông báo tới website owner và người mua hàng
 * @param {*} địa chỉ email và nội dung tin nhắn
 * Thiết lập biến môi trường EMAIL_API_KEY trên server.
 */
const sendEmailToAdmin = async (content = "") => {
  const body = {
    sender: {  
      "name":"VNTALKING",
      "email":"no-reply@vntalking.com"
    },
    to:[  
      {  
        "email": store.getters.emailService.recipient,
        "name": "Admin"
      }
    ],
    subject:"Tuyệt vời! Có đơn đặt sách mới",
    htmlContent: content
  }
  return httpClient.post(`${store.getters.emailService.endpoint}`, body, {
    headers: {
      'api-key': `${store.getters.emailService.apiKey}`,
      'accept': 'application/json',
      'content-type': 'application/json'
    }
  })
};

export {
  sendEmailToAdmin
}