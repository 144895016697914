import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import helper from './plugins/helper'
import { firestorePlugin  } from 'vuefire';
import { firebaseApp } from './services/firebase';

Vue.config.productionTip = false;

Vue.use(firestorePlugin);

// Importing the global css file
import "../src/assets/css/main.css";

new Vue({
  router,
  store,
  vuetify,
  helper,
  firebaseApp,
  render: h => h(App)
}).$mount('#app')
