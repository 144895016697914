<script>
import HeaderInfo from '../components/Header.vue';
import { mapGetters } from 'vuex';

export default {
   name: 'Done',
   components: {HeaderInfo},
   computed: {
    ...mapGetters(['orderInfo']),
   },
   methods: {
    reload() {
      window.location.reload()
    }
   }
}
</script>
<template>
<v-row>
    <v-col cols="12" md="12">
      <div class="block main-content">
        <h1 class="d-flex align-center">Tuyệt vời! 🤩</h1>
        <span>Đã đặt sách thành công. Cám ơn bạn rất nhiều khi đã ủng hộ đặt sách trên VNTALKING. <br><br>Ngay khi xác nhận đã nhận được thanh toán, bên mình sẽ gửi sách luôn cho bạn.</span><br>
        <span>Vui lòng kiểm tra hộp thư email <b>{{orderInfo.buyer.email || "Có vẻ sai sai, hình như chưa điền email"}}</b> để nhận sách nhé.</span><br><br>
        <div>
          Trong trường hợp chưa nhận được sách ngay, đừng ngần ngại liên hệ với mình qua các kênh bên dưới đây nhé.
          <ul>
            <li>Fanpage: <a href="https://facebook.com/vntalking" target="_blank" rel="noopener noreferrer">https://facebook.com/vntalking</a></li>
            <li>Email: <a href="mailto:support@vntalking.com">support@vntalking.com</a></li>
          </ul>
        </div>
        <div class=" flex-lg-row">
          <v-btn class="mt-5 mr-5" color="secondary" dark elevation="2" @click="reload">Đặt sách lại</v-btn>
          <v-btn class="mt-5" color="primary" dark elevation="2" href="https://vntalking.com">Về trang chủ</v-btn>
        </div>
        
      </div>
    </v-col>
  </v-row>
</template>
