<script>
import BlockTitle from './BlockTitle.vue';
import BookItem from './items/BookItem.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'OrderSummary',
  components:{BlockTitle, BookItem},
  data: () => ({}),
  computed: {
    ...mapGetters(['orderSummary', "orderInfo"])
  }
}
</script>

<template>
  <div class="order-summarry-containner component">
    <block-title text="Thông tin đơn hàng"/>
    <div class="amount-summary d-flex justify-space-between">
      <div class="label">Tổng tiền:</div>
      <span class="amount">{{this.$helper.formatCurrency(orderSummary?.amount)}}</span>
    </div>
    <v-divider class="mt-2 mb-2"/>
    <div class="book-chose-label">Các sách đã chọn:</div>
    <div v-if="orderSummary.books?.length">
      <book-item
        v-for="(book, index) in orderSummary.books" :key="index"
        :bookName="book.bookName"
        :price="book.price"
        :priceMarketing="book.priceMarketing"
        :cover="book.cover"
        :code="book.code"
        :editable="false"
        :link="book.link"
      />
    </div>
    <div v-else class="empty-chose-book">Bạn chưa chọn cuốn sách nào cả! 😟</div>
    
  </div>
  
</template>

<style scoped>
.book-chose-label {
  color: black;
  font-weight: bold;
}

.amount-summary .label {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.amount-summary .amount {
  color: #4caf50;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

.transaction-content {
  font-size: 15px;
  color: black;
  text-transform: uppercase;
}

.transaction-content span {
  color: #FF5252;
}

.empty-chose-book {
  color: gray;
  font-size: 15px;
}
</style>