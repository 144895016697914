import { OrderPagesCollection, ConfigCollection} from '../../services/firebase';
/**
 * Lấy toàn bộ dữ liệu khởi tạo page.
 * Giải mã và lưu vào trong store.
 */
const getInitData = async () => {
  return ConfigCollection.get("rootConfig");
};

/**
 * Lưu thông tin đơn hàng vào database
 * @param {} data - thông tin đơn hàng
 */
const save = async (orderInfo) => {
  orderInfo = {...orderInfo, time: new Date().getTime()}
  // có thể xử lý qua dữ liệu (TBD)
  return OrderPagesCollection.add(orderInfo);
};

export {
  getInitData,
  save
}


