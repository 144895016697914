<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Warning.Dialog',
  props:["title", "message"],
  computed: {
    ...mapGetters(['isShowDialog']),
  },
  methods:{
    ...mapActions(["hideDialog"]),
  },
  computed: {
    isShowDialog: {
      get () {
        return this.$store.state.ui.showDialog;
      },
      set (value) {
        this.$store.commit('UPDATE_DIALOG_STATE', value)
      }
    },
  }
}
</script>

<template>
  <v-dialog v-model="isShowDialog" width="500">
    <v-card>
      <v-card-title class="text-h5 orange darken-1">
        <span class="dialog-title">Ố ồ</span>
      </v-card-title>
      <v-card-text class="mt-3 mb-2 dialog-message" v-html="message"></v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="()=> hideDialog()">Đồng ý</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
  .dialog-title {
    color: white;
  }

  .dialog-message {
    color: black !important;
  }
</style>