import axios from "axios";

const httpClient = axios.create({
  headers: {
    device: "WEB",
    client: navigator.userAgent,
    "Cache-Control": "no-cache, no-store",
    "Content-Type": "application/json"
  },
});

httpClient.interceptors.request.use();
httpClient.interceptors.response.use();

export default httpClient;