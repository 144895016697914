import Vue from 'vue'
import StringUtils from '@/utils/StringUtils';

const plugin = {
  install () {
    Vue.StringUtils = StringUtils;
    Vue.prototype.$helper = StringUtils;
  }
}

Vue.use(plugin)
export default StringUtils;