<script>
  import HeaderInfo from '../components/Header.vue';
  import BooksList from '../components/BooksList.vue';
  import Coupon from '../components/Coupon.vue';
  import OrderButtonsGroup from '../components/OrderButtonsGroup.vue';
  import OrderSummary from '../components/OrderSummary.vue';
  import Survey from '../components/Survey.vue';
  import Buyer from '../components/Buyer.vue';
  import Contact from '../components/Contact.vue';
  import WarningDialog from '../components/dialogs/Warning.Dialog.vue';

  import { mapGetters } from 'vuex';

  export default {
    name: 'Home',
    components: {
      HeaderInfo,
      BooksList,
      Coupon,
      OrderButtonsGroup,
      OrderSummary,
      Survey,
      Buyer,
      Contact,
      WarningDialog
    },
    computed: {
      ...mapGetters(['isLoading', 'dialogMessage'])
    }
  }
</script>

<template>
  <v-row>
    <v-col cols="12" md="7">
      <div class="block main-content">
        <header-info/>
        <buyer/>
        <books-list/>
        <survey/>
        <contact/>
      </div>
    </v-col>
    <v-col cols="12" md="5" >
      <div class="block sidebar">
        <order-summary/>
        <coupon/>
        <order-buttons-group/>
      </div>
    </v-col>
  </v-row>
</template>