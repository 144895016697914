<script>
import { mapActions, mapGetters} from 'vuex';

export default {
  name: 'ConfirmButtonGroup',
  data: () => ({}),
  computed: {
    ...mapGetters(['orderInfo'])
  },
  methods: {
    ...mapActions(["showDialog", "changeStep"]),
    submit() {
      // validate các trường bắt buộc
      const {buyer : {name, email}} = this.orderInfo;
      // validate Email
      if(!email || email == "" || !this.isValidEmail(email)) {
        return this.showDialog("Trường <b>Địa chỉ email</b> có vẻ không hợp lệ. Mình cần thông tin này để gửi sách cho bạn. Bạn vui lòng kiểm tra lại nhé!");
      }
       // validate tên
      if(!name || name == "") return this.showDialog("Không được để trống trường <b>Tên của bạn</b>. Mình cần thông tin này để xác nhận thanh toán. Bạn vui lòng điền vào nhé.");
      // validate sách chưa chọn
      if(this.orderInfo.orderSummary.books?.length == 0) return this.showDialog("Bạn quên chưa chọn sách nào rồi! </br> Hãy chọn một cuốn sách ưng ý nhất nhé. Mua theo combo sẽ có giá rẻ hơn đấy.")

      this.gotoPaymentPage();
    },
    gotoPaymentPage() {
      // this.$router.push('/payment');
      this.changeStep(2)
    },
    isValidEmail(emailtest) {
      const isEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailtest);
      return isEmail;
    }
  },
}
</script>

<template>
  <div class="button-group-containner component">
    <v-divider class="o-divider"/>
    
    <v-btn class="mt-2" block color="green" dark elevation="2" @click="submit">Thanh toán</v-btn>
  </div>
  
</template>

<style scoped>
  .button-group-containner {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .o-divider {
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>