<script>
import BlockTitle from './BlockTitle.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'PaymentInfo',
  components:{BlockTitle},
  data: () => ({}),
  computed: {
    ...mapGetters(['paymentInfo', "transferContent"])
  }
}
</script>

<template>
  <div class="payment-info-containner component">
    <block-title text="Thông tin thanh toán"/>
    <div v-html="paymentInfo.note" class="note"></div>
    <v-divider class="custom-divider" />
    <div class="tranform-content">Nội dung chuyển khoản: <span>{{`${transferContent}` || "Vui lòng chọn sách nào đó"}}</span></div>
  </div>
  
</template>

<style scoped>
  .tranform-content {
    font-weight: bold;
  }

  .tranform-content span {
    color: #FF5252;
  }

  .custom-divider {
    margin-top: 8px;
    margin-bottom: 8px;
  }
</style>