<script>
  import WarningDialog from '../components/dialogs/Warning.Dialog.vue';
  import HomeView from './HomeView.vue';
  import PaymentInfo from './PaymentInfo.vue';
  import ResultPage from './ResultView.vue'

  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'Main',
    components: { HomeView, PaymentInfo, ResultPage, WarningDialog},
    computed: {
      ...mapGetters(['isLoading', 'dialogMessage']),
      step: {
        get () {
          return this.$store.state.ui.step;
        },
        set (value) {
          this.$store.commit('UPDATE_STEP', value)
        }
      },
    },
    methods: {
      ...mapActions(["displayLoading","initPageData"])
    },
    async created() {
      this.displayLoading(true);
      try {
        await this.initPageData();
      } catch (error) {
        console.log(error);
      } finally {
        this.displayLoading(false);
      }
    }
  }
</script>

<template>
  <v-container style="max-width: 1050px">
    <v-stepper non-linear v-model="step" alt-labels>
      <v-stepper-header>
        <v-stepper-step :complete="step > 0" step="1" editable> Chọn sản phẩm</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 1" step="2"> Thanh toán</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :complete="step > 2"> Hoàn thành</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <!-- màn hình Chọn sản phẩm -->
          <home-view/>
        </v-stepper-content>
        <v-stepper-content step="2">
          <!-- màn hình thông tin thanh toán -->
          <payment-info/>
        </v-stepper-content>
        <v-stepper-content step="3" >
          <!-- màn hình cám ơn -->
          <result-page/>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <warning-dialog :message="dialogMessage"/>
    <v-overlay :value="isLoading" opacity="0.8">
      <div class="d-flex flex-column align-center">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
        <span class="mt-2">Đợi xíu xiu nhé</span>
      </div>
    </v-overlay>
  </v-container>
</template>