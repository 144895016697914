<script>
import BlockTitle from './BlockTitle.vue';

export default {
  name: 'Buyer',
  components:{BlockTitle},
  data: () => ({
    nameValidatorRules: [
      v => !!v || 'Tên phải bắt buộc nhập',
      v => v.length <= 100 || 'Thông tin họ và tên không hợp lệ.'
    ],
    emailValidatorRules: [ 
      v => !!v || 'Địa chỉ email là bắt buộc phải nhập',
      v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Địa chỉ email không hợp lệ'
    ]
  }),
  computed: {
    name: {
      get () {
        return this.$store.state.orderInfo.buyer.name;
      },
      set (value) {
        this.$store.commit('UPDATE_NAME', value)
      }
    },
    email: {
      get () {
        return this.$store.state.orderInfo.buyer.email;
      },
      set (value) {
        this.$store.commit('UPDATE_EMAIL', value)
      }
    }
  }
}
</script>

<template>
  <div class="buyer-containner component">
    <block-title text="Thông tin người đặt" :required="true"/>
    <div class="message">Vui lòng điền thông tin email chính xác để mình gửi sách vào email của bạn không bị thất lạc.</div>
    <v-row>
        <v-col cols="12" lg="3"><span class="label">Địa chỉ Email:</span></v-col>
        <v-col cols="12" lg="9">
          <v-text-field dense outlined v-model="email" placeholder="Điền địa chỉ email" :rules="emailValidatorRules"></v-text-field>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3"><span class="label">Tên của bạn:</span></v-col>
      <v-col cols="12" lg="9">
        <v-text-field dense outlined v-model="name" placeholder="Điền tên của bạn" :rules="nameValidatorRules"></v-text-field>
      </v-col>
    </v-row>
  </div>
  
</template>

<style scoped>
  .label {
    font-weight: bold;
  }

  .message {
    margin-bottom: 18px;
    font-size: 16px;
  }
  .col-12 {
    padding: 12px 12px 0 12px;
  }

</style>