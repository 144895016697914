<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  data: () => ({}),
  computed: {
    ...mapGetters(['header'])
  }
}
</script>

<template>
  <div class="header-containner">
    <v-img class="background" max-height="150" contain :src="header.background"/>
    <div class="custom-title">{{header.title}}</div>
    <div class="sub-title">{{header.subTitle}}</div>
  </div>
</template>

<style scoped>
  .background {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .custom-title {
    text-align: center;
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 10px;
  }

  .sub-title {
    font-size: 16px;
    font-style: italic;
    color: darkgray;
    text-align: center;
  }

</style>