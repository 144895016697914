<script>
export default {
  name: 'BlockTitle',
  props: {
    text: {
      type: String,
      default: "Tiêu đề"
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({})
}
</script>

<template>
  <div class="title-container">
    <div class="block-title">{{text}} <span v-if="required" class="require-symbol">*</span></div>
    <div class="custom-divider"><span></span></div>
  </div>
  
</template>

<style scoped>
.block-title {
  color: black;
  font-size: 20px;
  font-weight: bold;
}
.title-container {
  width: 100%;
  box-sizing: border-box;
}

.custom-divider {
  border-bottom: 1px solid #FFF;
  background-color: #DADADA;
  height: 2px;
  margin: 0.5em 0px 0.8em;
}

.custom-divider span {
  display: block;
  width: 65px;
  height: 1px;
  background-color: #F60;
}

.block-title .require-symbol {
  color: brown;
}

</style>