<script>
import BlockTitle from './BlockTitle.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Coupon',
  components:{BlockTitle},
  data: () => ({
    couponApplied: "",
    couponAppliedStatus: false,
    showMessage: false,
    disabled: false
  }),
  computed: {
    ...mapGetters(['couponsData', 'orderInfo'])
  },
  methods: {
    ...mapActions(["applyCoupon"]),
    apply() {
      const coupon = this.couponApplied?.toUpperCase()?.trim() || "";
      // Kiểm tra mã giảm giá hợp lệ và còn hạn sử dụng hay không?
      const foundInvalidCoupon = this.couponsData.find(c => c.code?.toUpperCase()?.trim() == coupon && c.expireAt > new Date().getTime());
      if(!foundInvalidCoupon) {
        this.showMessage = true;
        this.couponAppliedStatus = false;
        this.disabled = false;
        // Remove coupon trong store.
        this.applyCoupon({code: "", discount: 0});
        return;
      }
      // dispatch apply coupon action
      this.applyCoupon(foundInvalidCoupon);
      this.showMessage = true;
      this.couponAppliedStatus = true;
      this.disabled = true;
    }
  },
  
}
</script>

<template>
  <div class="coupon-containner component">
    <block-title text="Mã giảm giá (nếu có)"/>
    <v-row class="mt-5 mb-5">
      <v-col cols="12" lg="6" class="mb-3">
        <v-text-field hide-details="auto" :disabled="disabled" dense outlined v-model="couponApplied" placeholder="Điền mã giảm giá"></v-text-field>
        <span v-if="showMessage && !couponAppliedStatus" class="coupon-message coupon-invalid">Không hợp lệ hoặc đã hết hạn</span>
        <span v-if="showMessage && couponAppliedStatus" class="coupon-message coupon-success">Áp dụng mã giảm giá thành công </span>
      </v-col>
      <v-col cols="12" lg="6">
        <v-btn :disabled="disabled" light color="secondary" @click="apply"><v-icon left>mdi-ticket-percent-outline</v-icon>Áp dụng</v-btn>
      </v-col>
    </v-row>
  </div>
  
</template>

<style scoped>
  .col-12 {
    padding: 0 12px 0 12px;
  }

  .coupon-message {
    font-size: 13px;

  }
  .coupon-invalid {
    color: #ff5252 ;
  }

  .coupon-success {
    color: #4caf50;
  }


</style>