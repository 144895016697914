<script>
import BlockTitle from './BlockTitle.vue';
import BookItem from './items/BookItem.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'BookList',
  components: { BookItem, BlockTitle},
  data: () => ({}),
  computed: {
    ...mapGetters(['books'])
  }
}
</script>

<template>
  <div class="blockslist-containner component">
    <block-title text="Các sách hiện có" :required="true"/>
    <book-item
      v-for="(book, index) in books" :key="index"
      :bookName="book.bookName"
      :price="book.price"
      :priceMarketing="book.priceMarketing"
      :cover="book.cover"
      :code="book.code"
      :link="book.link"
    ></book-item>
  </div>
  
</template>

<style scoped>

</style>