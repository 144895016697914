import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    light: true, 
    themes: {
      light: {
        primary: '#4caf50',
        secondary: "#1976D2"
      },
    },
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
});
