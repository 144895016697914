import { render, staticRenderFns } from "./Coupon.vue?vue&type=template&id=45e539bb&scoped=true&"
import script from "./Coupon.vue?vue&type=script&lang=js&"
export * from "./Coupon.vue?vue&type=script&lang=js&"
import style0 from "./Coupon.vue?vue&type=style&index=0&id=45e539bb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e539bb",
  null
  
)

export default component.exports